// AppInsights.js
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const instrumentationKey = process.env.GATSBY_INSTRUMENTATION_KEY;
const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
let appInsights;
if (instrumentationKey) {
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });
  appInsights.loadAppInsights();
} else {
  console.warn('Application insights key not available.');
}
export { reactPlugin, appInsights };
