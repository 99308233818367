// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learn-more-tsx": () => import("./../../../src/pages/learn-more.tsx" /* webpackChunkName: "component---src-pages-learn-more-tsx" */),
  "component---src-pages-review-[id]-tsx": () => import("./../../../src/pages/review/[id].tsx" /* webpackChunkName: "component---src-pages-review-[id]-tsx" */),
  "component---src-pages-reviews-board-[abbreviation]-tsx": () => import("./../../../src/pages/reviews/board/[abbreviation].tsx" /* webpackChunkName: "component---src-pages-reviews-board-[abbreviation]-tsx" */),
  "component---src-pages-reviews-tsx": () => import("./../../../src/pages/reviews.tsx" /* webpackChunkName: "component---src-pages-reviews-tsx" */)
}

